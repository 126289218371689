import * as React from "react"

import Layout from "@components/layout"
import Seo from "@components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <div className="container-new" style={{ minHeight: "80vh" }}>
      <h1 className="font-130">404: Not Found</h1>
      <p className="font-55">You hit a route that doesn&#39;t exist</p>
    </div>
  </Layout>
)

export default NotFoundPage
